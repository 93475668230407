import styled from "@emotion/styled"

import { Link } from "gatsby"

import BuyButton from "../BuyButton"

import { mediaQueries } from "../../styles"

export const Wrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 20rem;
  margin: 0 auto;
  text-align: center;
  text-decoration: none;

  .gatsby-image-wrapper {
    width: 100%;
    height: 0;
    padding-top: 100%;
    margin-bottom: 3.75rem;

    ${mediaQueries.max576} {
      margin-bottom: 1.5rem;
    }
  }
`

export const Name = styled.h4`
  margin-bottom: 0.5rem;
  color: var(--primary);
  font-size: 1rem;
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 3px;
`

export const Weight = styled.span`
  margin-bottom: 1rem;
  color: var(--secondary-light);
  line-height: 1;
`

export const PriceButton = styled(BuyButton)`
  letter-spacing: 3px;
`
