import { graphql, PageProps } from "gatsby"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { Trans } from "gatsby-plugin-react-i18next"

import { StaticImage } from "gatsby-plugin-image"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Layout from "../components/Layout"
import Section from "../components/Section"
import Container from "react-bootstrap/Container"
import { ProductsSection } from "../components/HomePage"
import Product from "../components/Product"
import Banner from "../components/Banner"
import RecipesGrid from "../components/RecipesGrid"
import ContactSection from "../components/ContactSection"

import { ProductCompact as ProductCompactType } from "../types/Product"
import { Recipe as RecipeType } from "../types/Recipe"
import { AdBanner } from "../types/AdBanner"

type Props = PageProps<{
  products: { nodes: ProductCompactType[] }
  recipes: { nodes: RecipeType[] }
  adBanner: AdBanner
}>

const CatalogPage = ({ data }: Props) => {
  const { products, recipes, adBanner } = data

  return (
    <Layout hasHero={false}>
      <section>
        <StaticImage
          src="../images/catalog/catalog-hero.jpg"
          alt="Catalogo"
          quality={100}
          layout="fullWidth"
        />
      </section>

      <StyledProductSection>
        <Container>
          <Trans
            i18nKey="products.heading"
            components={{ bold: <strong />, heading: <h3 /> }}
          />
        </Container>

        <Container>
          <Row>
            {products.nodes.map(product => (
              <Col md={4} lg={4} key={product.id}>
                <StyledProduct {...product} />
              </Col>
            ))}
          </Row>
        </Container>
      </StyledProductSection>

      <Banner
        title={adBanner.text}
        buttonText={adBanner.buttonText}
        buttonLink={adBanner.buttonLink}
        background={adBanner.background.gatsbyImageData}
        backgroundMobile={adBanner.backgroundMobile.gatsbyImageData}
        css={css`
          margin-bottom: 0;
        `}
      />

      <Section>
        <Container>
          <Row className="justify-content-center">
            <Col className="col-xxl-9">
              <RecipesGrid recipes={recipes.nodes} />
            </Col>
          </Row>
        </Container>
      </Section>

      <ContactSection />
    </Layout>
  )
}

export default CatalogPage

const StyledProductSection = styled(ProductsSection)`
  padding-bottom: 0;
`

const StyledProduct = styled(Product)`
  margin-bottom: 10rem;
`

export const query = () =>
  graphql`
    query ($language: String!) {
      locales: allLocale(filter: { language: { eq: $language } }) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
      adBanner: datoCmsCataloguePage(locale: { eq: $language }) {
        text: adBannerText
        subtitle: adBannerSubtitle
        buttonText: adBannerButtonText
        buttonLink: adBannerButtonLink
        backgroundMobile: adBannerBackgroundMobile {
          gatsbyImageData
        }
        background: adBannerBackground {
          gatsbyImageData
        }
      }
      products: allDatoCmsProduct(
        sort: { fields: position }
        filter: { locale: { eq: $language } }
      ) {
        nodes {
          id
          name
          slug
          price
          weight
          photo {
            gatsbyImageData(width: 320)
          }
        }
      }
      recipes: allDatoCmsRecipe(limit: 3, filter: { locale: { eq: $language } }) {
        nodes {
          id
          slug
          mainPhoto {
            gatsbyImageData
          }
          tagline
          title
          description
        }
      }
    }
  `
