import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import { Wrapper, Name, Weight, PriceButton } from "./styles"

import type { ProductCompact } from "../../types/Product"

const Product: React.FC<ProductCompact & { className?: string }> = ({
  id,
  slug,
  photo,
  name,
  price,
  weight,
  isPhysical,
  isSubscription,
  variants,
  subscriptionInterval,
  className,
}) => {
  return (
    <Wrapper to={`/${slug}`} className={className}>
      <GatsbyImage
        image={photo.gatsbyImageData}
        alt={photo.alt || name}
        imgStyle={{ objectFit: `contain` }}
      />
      <Name>{name}</Name>
      <Weight>{weight}</Weight>
      <PriceButton
        title={`RD$${price}`}
        product={{
          id,
          slug,
          photo,
          name,
          price,
          weight,
          isPhysical,
          isSubscription,
          variants,
          subscriptionInterval,
        }}
      />
    </Wrapper>
  )
}

export default Product
